// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isOneOfMiniprograms } from '@utils/native';
import './styles.scss';

NavBar.propTypes = {
    left: PropTypes.node,
    center: PropTypes.node,
    right: PropTypes.node,
    className: PropTypes.string,
    isSticky: PropTypes.bool,
};

NavBar.defaultProps = {
    left: null,
    center: null,
    right: null,
    className: undefined,
    isSticky: false,
};

export default function NavBar(props) {
    const { left, center, right, className, isSticky, style = {} } = props;
    const cls = classnames(className, 'component-nav-bar', {
        'component-nav-bar-sticky': isSticky,
    });

    const centerCls = classnames('component-nav-bar-center', {
        mini: isOneOfMiniprograms(),
        'component-nav-bar-end': !isOneOfMiniprograms()
    });

    const rightCls = classnames('component-nav-bar-right', {
        'component-nav-bar-right_mini': isOneOfMiniprograms()
    });

    return (
        <nav id="nav-bar" className={cls} style={style}>
            <div className="component-nav-bar-left">{left}</div>
            <div className={centerCls}>{center}</div>
            <div className={rightCls}>{right}</div>
        </nav>
    );
}
