// @ts-nocheck
import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import BottomArrow from '../BottomArrow';
import classnames from 'classnames';
import DateUitl from '@utils/date';
import noop from '@utils/noop';
import { LeftBtn, Right } from './Widget';
import {
    isOneOfMiniprograms,
    isOneOfQuickApp
} from '@utils/native';
import './styles.scss';

export default class CarListNavBar extends Component {
    static propTypes = {
        title: PropTypes.string,
        canEdit: PropTypes.bool,
        isRight: PropTypes.bool,
        style: PropTypes.object,
        condition: PropTypes.object,
        onTrigger: PropTypes.func,
        loading: PropTypes.bool
    };

    static defaultProps = {
        title: document.title,
        canEdit: true,
        style: {}
    };

    componentDidMount() {

    }

    shouldComponentUpdate(nextProps) {
        const { title, condition } = this.props;
        return title !== nextProps.title || condition.pickup !== nextProps.condition?.pickup;
    }

    handleOnTrigger = () => {
        const { loading, onTrigger } = this.props;
        if (loading) {
            return false;
        } else {
            onTrigger && onTrigger();
        }
    };

    render() {
        const { onLeft, onRight, canEdit = true, isRight, style = {}, ...reset } = this.props;
        return (
            <NavBar
                style={style}
                isSticky
                className="component-nav-bar-search"
                left={
                    isOneOfMiniprograms() ? null : (
                        <LeftBtn onClick={onLeft} />
                    )
                }
                center={(
                    <CenterTitle
                        onClick={this.handleOnTrigger}
                        {...reset}
                        canEdit={canEdit}
                    />
                )}
                right={
                    (isRight && !isOneOfQuickApp()) ? (
                        <Right
                            onClick={onRight}
                        />
                    ) : null
                }
            />
        );
    }
}

const CenterTitle = memo((props) => {
    // 时间格式兼容
    const {
        onClick,
        canEdit,
        condition,
        titleStyle = {},
        arrowStyle,
        tj,
    } = props;
    const pickTimes = condition?.pickup?.timeStr || condition?.pickup?.pickTime || condition?.pickup?.time;
    const dropTimes = condition?.dropoff?.timeStr || condition?.dropoff?.dropTime || condition?.dropoff?.time;
    const formatPickTime = DateUitl.format(pickTimes, 'MM-DD HH:mm');
    const formatDropTime = DateUitl.format(dropTimes, 'MM-DD HH:mm');
    const times = DateUitl.diff({ start: pickTimes, end: dropTimes }).times;
    const cls = classnames({
        'component-nav-bar-search-center-box': true,
        long: times.toString().length >= 6,
        mini: isOneOfMiniprograms(),
        quickapp: isOneOfQuickApp(),
        normal: !canEdit,
    });
    return (
        <div className={cls} data-tj={tj} onClick={!!canEdit ? onClick : noop} style={titleStyle}>
            <div className="search-pick-item txt-right">
                <div className="search-pick-item-text">{condition?.pickup?.landmarkName ?? '请选择取车地点'}</div>
                <div className="search-pick-item-time">{formatPickTime}</div>
            </div>
            <div className="search-day-item">
                <div className="duration">{times}</div>
                <BottomArrow arrowStyle={arrowStyle} />
            </div>
            <div className="search-pick-item">
                <div className="search-pick-item-text">{condition?.dropoff?.landmarkName ?? '请选择还车地点'}</div>
                <div className="search-pick-item-time">{formatDropTime}</div>
            </div>
        </div>
    );
});
