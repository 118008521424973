import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NavBar from '../NavBar';
import { isApp, setTitle, setAppRightBarList, baseHost, orderHost } from '@utils/native';
import getKeFuChatUrl from '@utils/getKeFuChatUrl';

const HEIGHT = document.documentElement.offsetHeight;

/**
 * 页面组件。满屏，内部滚动
 * 注意：依赖HTML高度
 */
interface PageFullScreenProps {
    defaultNavBarProps?: any
    useRightBar?: any
    children?: any
    rightBarList?: any
    style?: any,
    className?: string,
    navBar?: any,
    defaultNavBar?: any
}
export default class PageFullScreen extends React.Component<PageFullScreenProps> {
    static propTypes = {
        defaultNavBar: PropTypes.bool,
        defaultNavBarProps: PropTypes.shape({
            title: PropTypes.string,
        }),
        navBar: PropTypes.node,
        children: PropTypes.node.isRequired,
        // eslint-disable-next-line
        style: PropTypes.object,
        rightBarList: PropTypes.object,
        useRightBar: PropTypes.bool,
    };

    static defaultProps = {
        defaultNavBar: true,
        defaultNavBarProps: {},
        navBar: null,
        style: {},
        useRightBar: true,
        rightBarList: {
            customItems: [
                {
                    type: 'myOrder',
                    title: '我的订单',
                    url: `${orderHost}/account/order.php?tabs=crc`,
                    isNeedLogin: true,
                },
                {
                    type: 'systemMessage',
                    title: '信息',
                    url: `${baseHost}/group/chat/?_page=messages`,
                    isNeedLogin: true,
                },
                {
                    type: 'home',
                    title: '首页',
                    url: `${baseHost}/zijia/`,
                },
                {
                    type: 'contactCS',
                    title: '客服中心',
                    url: getKeFuChatUrl(),
                },
            ],
        },
    };

    componentDidMount() {
        this.setTitle();
        this.setRightBarList();
    }

    componentDidUpdate(prevProps) {
        const { defaultNavBarProps } = this.props;
        const { title } = defaultNavBarProps;

        if (title !== prevProps.defaultNavBarProps.title) {
            this.setTitle();
        }
    }

    setRightBarList() {
        const { useRightBar, rightBarList } = this.props;
        useRightBar && setAppRightBarList(rightBarList);
    }

    setTitle() {
        const { defaultNavBarProps } = this.props;
        const { title } = defaultNavBarProps;
        document.title = title;
        setTitle(title);
    }

    render() {
        const {
            children,
            style,
            className,
            useRightBar,
            rightBarList,
            navBar,
            defaultNavBar,
            defaultNavBarProps,
            ...rest
        } = this.props;
        // React 16.x 之后 若是props中有使用驼峰命名的属性，如useRightBar, rightBarList,
        // 同时又使用到 {...props}或者 {...reset}时，需要将useRightBar, rightBarList结构出来，否则将会报错
        // 或者规避 {...props}这样的写法咯
        // 好奇怪的warning
        let targetNavBar = navBar;
        if (!targetNavBar && defaultNavBar) {
            targetNavBar = <NavBar {...defaultNavBarProps} />;
        }

        const cls = classnames('component-page component-page-full-screen', className);

        return (
            <main
                {...rest}
                className={cls}
                style={{
                    height: HEIGHT,
                    ...style,
                }}
            >
                {targetNavBar && !isApp() && (
                    <div className="component-page-full-screen-header">
                        {targetNavBar}
                    </div>
                )}
                <div className="component-page-full-screen-body">{children}</div>
            </main>
        );
    }
}
