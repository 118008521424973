// const call = zzc.call;

// window.zzc.call = (...args: any[]) => {
//   console.log('zzc.call', args);
//   return call.apply(window.zzc, args);
// };
// const zzc = (window as any).zzc || {};
import * as utilLogger from '@utils/logger';
import {getPartnerId, getUserId, getServerCode} from '@utils/cookie'
let zzc = window.zzc || {};
const handler = {
    get: (target: any, prop: string, receiver: any) => {
        console.log('zzc.get', prop);
        if(prop === 'call') {
            utilLogger.warn(`zzc获取${prop}-${getServerCode()}-${getUserId()}-${getPartnerId()}`, {
                extra: {
                    prop,
                    zzcjsbridge: window.zzcjsbridge,
                    zzc: window.zzc,
                    target: target,
                    ServerCode: getServerCode(),
                    UserId: getUserId(),
                    PartnerId: getPartnerId()
                }
            })
        }
        return target[prop];
    }
};

const proxy = new Proxy(window.zzc || {}, handler);

zzc = proxy;



export default zzc;