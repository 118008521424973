// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import { Logo } from './Widget';

export default class BarLogo extends React.Component {
    render() {
        const { logo } = this.props;

        return (
            <NavBar
                className="component-nav-bar-default"
                center={<Logo logo={logo} />}
            />
        );
    }
}
