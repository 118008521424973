/**
 * 渲染React页面
 */
import '../style';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import setUtms from '../utils/setUtms';
import { ErrorBoundaryPage } from '../components/ErrorBoundary';

export default function render(PageComponent, store?: any, cb?: Function | void) {
    // 设置utm
    setUtms();
    try {
        ReactDOM.render(
            <ErrorBoundaryPage key="error">
                {
                    store ?
                        <Provider store={store}>
                            <PageComponent />
                        </Provider>
                        : <PageComponent />

                    }
            </ErrorBoundaryPage>,
            document.getElementById('react-app'),
            () => {
                hideInitLoading();
                cb && cb();
            }
        );
    } catch (error) {
        hideInitLoading();
        // eslint-disable-next-line
        if (window.confirm('程序出错，重新打开页面？')) {
            window.location.reload();
        }
    }
}

export function hideInitLoading() {
    const node = document.getElementById('js-render-service-code');
    const footerBar = document.getElementById('footer-bar');

    if (node) {
        if (!node.classList.contains('hidden')) {
            // 文档结构加载后再显示模版
            node.setAttribute('style', 'display: block;');
            // customerService();
        }
    }

    if (footerBar) {
        document.body.setAttribute('style', 'padding-bottom: 1.08rem');
    }
}
