// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import { BackBtn, Title, Right } from './Widget';
import { isOneOfMiniprograms } from '@utils/native';
import classNames from 'classnames';
export default class NavBarDefault extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        isSticky: PropTypes.bool,
        className: PropTypes.string,
        isRight: PropTypes.bool,
    };

    static defaultProps = {
        title: document.title,
        isSticky: false,
        className: PropTypes.string,
        isRight: false,
    };

    shouldComponentUpdate(nextProps) {
        const { title, className } = this.props;
        return title !== nextProps.title || className !== nextProps.className;
    }

    render() {
        const {
            title,
            onClick,
            isSticky,
            isRight,
            onRight,
            className,
            leftClassName,
            rightClassName,
        } = this.props;
        return (
            <NavBar
                isSticky
                className={classNames('component-nav-bar-default', className)}
                left={<BackBtn className={leftClassName} onClick={onClick} />}
                center={<Title title={title} />}
                // right={Right}
                right={
                    isRight &&
                    !isOneOfMiniprograms() && (
                        <Right className={rightClassName} onRight={onRight} />
                    )
                }
            />
        );
    }
}
