/**
 * Icon svg
 *
 * 用例：
 * ```js
 * import Icon from 'path/to/Icon';
 * ReactDOM.render(
 *   <div><Icon name='gift' /></div>,
 *   mountedNode
 * )
 * ```
 *
 * 新增：
 * 1. 将svg文件放到`./svg/`目录
 * 2. 在`./define.js`加载svg文件
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as mapSvgs from './define';

import './styles.scss';

Icon.propTypes = {
    // 与svg文件名相同（将“中划线”改为“下划线”）
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
};

Icon.defaultProps = {
    className: '',
    style: {},
};

export default function Icon(props) {
    const { name, className, style, isDefault } = props;
    // name
    let iconName = name.charAt(0).toUpperCase() + name.slice(1);
    const Svg = mapSvgs[iconName];

    if (!Svg) {
        // eslint-disable-next-line
        console.error(new Error(`未定义这个Icon: ${iconName}`));
        return null;
    }

    return (
        <i className={classnames(isDefault == false ? 'component-icon ': 'component-default-icon', className)} style={style}>
            <Svg />
        </i>
    );
}
