/**
 * React 异常边界
 */
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import * as utilLogger from '@utils/logger';

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    placeholder: PropTypes.node.isRequired
  };

  state = {
    error: null
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  // eslint-disable-next-line
  componentDidCatch(error, errorInfo) {
    // todo: 收集 errorInfo 信息
    // alert(error instanceof Error)/
    window.crcStopLoading && window.crcStopLoading();
    utilLogger.error(error);
  }

  render() {
    const { error } = this.state;
    const { children, placeholder } = this.props;

    if (error) {
      console.error("%c Line:36 🎂 error", "color:#b03734", error);
      return placeholder;
    }

    return children;
  }
}
