// @ts-nocheck
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  isApp,
  isPartner,
  setTitle,
  hideRightButton,
  setRightButton,
  isOneOfMiniprograms,
  isMiniprogram,
  isCgbApp,
  setCarListPageTitle,
  miniNameSpace,
} from '@utils/native';
import DateUitl from '@utils/date';
import { hideCRCTopBar } from '@utils/tools';
import { isNonEmptyString } from '@utils/type';
import RightBtn from './RightBtn';
import NavBar from '../NavBar';
/**
 * 页面组件。正常文档流
 */

interface PageNormalProps {
  defaultNavBarProps: {
    title: string,
    useCarNav?: boolean,
    className?: string,
    leftClassName?: string,
    rightClassName?: string,
    condition?: {
      pickup: any,
      dropoff: any
    };
    isRight?: boolean;
    onClick?: Function;
  },
  isAppRight?: boolean,
  isRight?: boolean,
  id?: string,
  navBar?: React.ReactNode,
  defaultNavBar?: boolean,
  shareContent?: string,
  sharePhoto?: string,
  shareUrl?: string,
  useNav?: boolean

}
export default class PageNormal extends Component<PageNormalProps, any> {
  static propTypes = {
    id: PropTypes.string,
    defaultNavBar: PropTypes.bool,
    defaultNavBarProps: PropTypes.shape({ ...NavBar.propTypes }),
    navBar: PropTypes.node,
    children: PropTypes.node.isRequired,
    useNav: PropTypes.bool,
    sharePhoto: PropTypes.string,
    shareContent: PropTypes.string,
    shareUrl: PropTypes.string,
  };

  static defaultProps = {
    id: '',
    defaultNavBar: true,
    defaultNavBarProps: {},
    navBar: null,
    useNav: false,
    isRight: false,
    isAppRight: false,
  };

  componentDidMount() {
    this.setTitle();
    this.setShare();
  }

  componentWillUnmount() {
    document.removeEventListener('deviceready', this.devicereadyCallback);
  }

  componentDidUpdate(prevProps: PageNormalProps) {
    const {
      sharePhoto = '',
      shareContent = '',
      shareUrl,
      defaultNavBarProps,
    } = this.props;
    const { title } = defaultNavBarProps;

    if (title !== prevProps.defaultNavBarProps.title) {
      this.setTitle();
    }
    if (
      sharePhoto !== prevProps.sharePhoto
      || shareContent !== prevProps.shareContent
      || shareUrl !== prevProps.shareUrl
    ) {
      this.setShare();
    }
  }

  setShare() {
    const {
      sharePhoto = '',
      shareContent = '',
      shareUrl,
    } = this.props;

    if (
      !isPartner() &&
      isOneOfMiniprograms()
      && isNonEmptyString(shareContent)
      && isNonEmptyString(sharePhoto)
    ) {
      miniNameSpace?.postMessage({
        data: {
          shareParams: {
            title: shareContent,
            url: shareUrl || window.location.href,
            imageUrl: sharePhoto,
            bgImgUrl: sharePhoto,
          },
        },
      });
    }
  }

  setTitle() {
    const { defaultNavBarProps } = this.props;
    const { title, useCarNav, condition } = defaultNavBarProps;

    if (!useCarNav) {
      if (title !== undefined) {
        document.title = title;
      }
      title && setTitle(title);
    } else {
      const pickTimes = condition?.pickup?.timeStr || condition?.pickup?.pickTime || condition?.pickup?.time;
      const dropTimes = condition?.dropoff?.timeStr || condition?.dropoff?.dropTime || condition?.dropoff?.time;
      const formatPickTime = DateUitl.format(pickTimes, 'MM-DD HH:mm');
      const formatDropTime = DateUitl.format(dropTimes, 'MM-DD HH:mm');

      document.title = `${condition?.pickup?.cityName || ''}租车报价`;

      const times = DateUitl.diff({
        start: pickTimes,
        end: dropTimes,
      }).times || '';
      setCarListPageTitle({
        pickUpLocation: condition?.pickup?.landmarkName ?? '',
        returnLocation: condition?.dropoff?.landmarkName ?? '',
        pickUpDate: formatPickTime,
        returnDate: formatDropTime || '',
        titleTips: times || '1天',
        onTouchBar: '',
        success() {},
      });
    }
    if (this.props.isAppRight) return;
    document.addEventListener('deviceready', this.devicereadyCallback);
  }

  devicereadyCallback = async () => {
    const { isRight } = this.props;

    if (!isRight) {
      hideRightButton();
    } else {
      setRightButton({
        type: 'image',
        imageW: '22',
        imageH: '22',
        imageUrl:
          'https://zzccdn.zuzuche.com/irc-common/upload_static/52/09/e9f2d16a5c11593b56eb6b6f37973d28.png',
        listener: () => {
          const moreContainer = document.getElementById('more-container');
          if (!moreContainer) return;
          if (!moreContainer.className.includes('show')) {
            moreContainer.className = 'more-container show';
          } else {
            moreContainer.className = 'more-container';
          }
        },
        success(response: any) {
          console.log(response);
        },
      });
    }
  };

  render() {
    const {
      id,
      children,
      navBar,
      defaultNavBar,
      defaultNavBarProps,
      shareContent = '',
      sharePhoto = '',
      useNav,
      isRight,
    } = this.props;
    let targetNavBar = navBar;
    if (!targetNavBar && defaultNavBar) {
      targetNavBar = <NavBar {...defaultNavBarProps} />;
    }

    return (
      <main id={id} className="component-page component-page-normal">
        {
          hideCRCTopBar()
          || (!useNav && isOneOfMiniprograms())
          || (window )?.__initData?.zzcPC
          || (!useNav && !targetNavBar)
          || (!useNav && isApp() && !isCgbApp())
            ? null
            : (
              <>{targetNavBar}</>
            )
        }
        {children}
        {isRight && (
          <RightBtn shareContent={shareContent} sharePhoto={sharePhoto} />
        )}
      </main>
    );
  }
}
