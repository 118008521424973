// import ClipboardJS from 'clipboard';
// @ts-nocheck
import copyToClipboard from 'copy-to-clipboard'
import { Toast } from 'zzc-design-mobile';
import { isApp, isCgbApp } from './native';


export default function copy(e, text, successText = '复制成功') {
    if (!text && e) {
        try {
            text = (e.target || e.currentTarget).innerText;
        } catch (error) {
            console.error('无可复制内容');
            return;
        }
    }
    if (isApp() && !isCgbApp()) {
        window.zzc && zzc.call('setNativeClipboard', {
            content: text,
            success: function (response) {
                // APP已经提示了暂时不用
                // Toast.info('复制成功', 1);
            }
        });
    } else {
        copyToClipboard(text)
        Toast.info(successText, 1);
        // const clipboard = new ClipboardJS(e.target);
        // e.target.setAttribute('data-clipboard-text', text);
        // clipboard.on('success', function (e) {
        //     e.clearSelection();
        //     Toast.info(successText, 1);
        //     clipboard.destroy();
        // });
        // e.target.click();
    }
}
