import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { jumpLink } from '@utils/url';
import { isOneOfMiniprograms, miniNameSpace, isPartner, isCgbApp } from '@/utils/native';
import { Icon as ZZCIcon } from 'zzc-design-mobile';
import classNames from 'classnames';
export function Title(props) {
    const { title } = props;

    return <h1 className="component-nav-bar-default-title">{title}</h1>;
}

Title.propTypes = {
    title: PropTypes.string,
};

Title.defaultProps = {
    title: document.title,
};

export function LeftBtn(props) {
    const { onClick } = props;
    return (
        <div
            role="button"
            className="component-nav-bar-default-back-btn back-home"
            onClick={event => {
                if (onClick) {
                    onClick(event);
                } else if (window.history.length > 1) {
                    window.history.go(-1);
                } else {
                    if (isPartner()) {
                        // 应广发方要求，直接返回原生app。
                        if (isCgbApp()) {
                            window.AlipayJSBridge &&
                                window.AlipayJSBridge.call('popWindow');
                        } else {
                            jumpLink({ url: '/' });
                        }
                    } else if (isOneOfMiniprograms()) {
                        miniNameSpace?.switchTab({
                            url: '/pages/home/index',
                        });
                    } else {
                        jumpLink({ url: '/' });
                    }
                }
            }}
        >
            <ZZCIcon type="goback" size="xs" />
        </div>
    );
}

LeftBtn.propTypes = {
    onClick: PropTypes.func,
};

export function BackBtn(props) {
    const { onClick, className } = props;
    return (
        <div
            role="button"
            className={classNames(
                'component-nav-bar-default-back-btn',
                'back-home',
                className
            )}
            onClick={event => {
                if (onClick) {
                    onClick(event);
                } else if (window.history.length > 1) {
                    window.history.go(-1);
                } else {
                    if (isPartner()) {
                        // 应广发方要求，直接返回原生app。
                        if (isCgbApp()) {
                            window.AlipayJSBridge &&
                                window.AlipayJSBridge.call('popWindow');
                        } else {
                            jumpLink({ url: '/' });
                        }
                    } else if (isOneOfMiniprograms()) {
                        miniNameSpace?.switchTab({
                            url: '/pages/home/index',
                        });
                    } else {
                        jumpLink({ url: '/' });
                    }
                }
            }}
        >
            {/* <Icon name="Back" /> */}
            <ZZCIcon type='goback' size='xs' />
        </div>
    );
}

BackBtn.propTypes = {
    onClick: PropTypes.func,
};

export function Right(props) {
    const { onClick, className } = props;
    return (
        <div
            role="button"
            className={classNames(
                'component-nav-bar-default-back-btn',
                'more-btn',
                className
            )}
            onClick={event => {
                if (onClick) {
                    onClick(event);
                } else {
                    const moreContainer = document.getElementById('more-container');
                    if (!moreContainer) return;
                    if (!moreContainer.className.includes('show')) {
                        console.log(1);
                        moreContainer.className = 'more-container show';
                    } else {
                        console.log(2);
                        moreContainer.className = 'more-container';
                    }
                }
            }}
        >
            {/* <Icon name="More" /> */}
            <ZZCIcon type='more' size='xs' />
        </div>
    );
}

Right.propTypes = {
    onClick: PropTypes.func,
};

export function Logo(props) {
    const { logo } = props;

    return <h1 className="component-nav-bar-default-logo">{logo}</h1>;
}
