/**
 * url拦截
 */

import { parseSearchParams } from '@utils/url';
import moment from 'moment';

export function hideCRCTopBar() {
    // CRCAppArg需要encode。。。。
    const { CRCAppArg } = parseSearchParams() as any;
    let hide = false;
    if (CRCAppArg) {
        const args = CRCAppArg.split(',');
        hide = args.some(item => item === 'hideTopBar=true');
    }
    return hide;
}

export function hideOpenApp() {
    // CRCAppArg需要encode。。。。
    const { CRCAppArg } = parseSearchParams() as any;
    let hide = false;
    if (CRCAppArg) {
        const args = CRCAppArg.split(',');
        hide = args.some(item => item === 'hideOpenApp=true');
    }
    return hide;
}

/**
 * 驼峰转下划线
 * @param {string} name 需要变更的字符串
 */
export function humpToLine(name) {
    return name.replace(/([A-Z])/g, '_$1').toLowerCase();
}

/**
 * 版本号转换为数值
 * @param {*} version
 * @param {*} length
 * @param {*} exponent
 */
export function versionToNumber(version, length, exponent) {
    let arr = [];
    if (arguments.length < 3) {
        return 0;
    }
    arr = version.split('.');
    version = 0;
    arr.forEach(function(value, index, array) {
        version += value * Math.pow(10, length * exponent - 1);
        length--;
    });
    return version;
}

/*
 * compare version
 * 比较版本号
 * 仅适用数字型的版本号
 * 0: 相等
 * 1: 大于
 * -1: 小于
 */
/**
 *
 * @param {*} version 当前版本号
 * @param {*} targetVersion 目标版本号
 * @param {*} exponent
 */
export function versionCompare(version, targetVersion, exponent) {
    let getVersionNumber, length;
    exponent = exponent || 2;
    if (!version || !targetVersion) {
        throw new Error('Need two versions to compare!');
    }
    if (version === targetVersion) {
        return 0;
    }
    length = Math.max(version.split('.').length, targetVersion.split('.').length);
    getVersionNumber = (function(length, exponent) {
        return function(version) {
            return versionToNumber(version, length, exponent);
        };
    })(length, exponent);
    version = getVersionNumber(version);
    targetVersion = getVersionNumber(targetVersion);
    return version > targetVersion ? 1 : version < targetVersion ? -1 : 0;
}

// console.log(versionCompare('1.0.4', '1.0.3', 3))

/**
 * @desc 判断当前地址信息是不是机场。
 *       原因：部分地址信息是来源天道系统，可能有部分地标没有"landmarkType"数据，因此做双重校验
 *       ①有些校验landmarkType。
 *       ②匹配地标名称landmarkName。
 * @param {Object} addressInfo 取/还车地址信息
 * @example
 *  {
 *  cityCode: "460200"
 *  cityName: "三亚"
 *  landmarkId: "0"
 *  landmarkName: "凤凰国际机场"
 *  landmarkType: ""
 *  lat: "18.307753"
 *  lng: "109.412161"
 *  provinceCode: 0
 *  time: 1611885600
 *  timeStr: "2021-01-29 10:00"
 *  toDoor: "0"
 *  wCityId: 102948
 *  }
 */
export function isAirportLandmark(addressInfo:any = {}) {
    if (addressInfo?.landmarkType) {
        return addressInfo.landmarkType === 'airport';
    } else {
        // 包含机场|機場|航站楼，且不以"路街"结尾
        return (
            /(机场|機場|航站楼)(\w*)/.test(addressInfo.landmarkName) &&
            /[^路街]$/.test(addressInfo.landmarkName)
        );
        // return /(机场|機場)?[^(路|街)]$/.test(addressInfo.landmarkName);
    }
}

/**
 * @desc 将"12:00"装换成moment时间格式
 * @returns {Object} moment
 * */
export function getMomentFromTimeString(timeString) {
    let str = timeString.split(':');
    let momentOptions = {
        hour: str[0],
        minute: str[1],
        second: 0,
        millisecond: 0,
    };
    return moment().set(momentOptions);
}
/**
 * sleep函数
 * @param {*} ms 毫秒
 */
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}