import React from 'react';
import {
    isCarRentalApp,
    isOneOfMiniprograms,
    isPartner,
    backToBlackHome,
    miniNameSpace,
} from '@utils/native';
import { jumpLink } from '@utils/url';
import copy from '@/utils/copy';
import Local from '@utils/local-storage';
/**
 * 头部右侧小程序
 */
interface RightBtnProps {
    shareContent: string;
    sharePhoto: string;
}
export default class RightBtn extends React.Component<RightBtnProps> {
    moreContainer = null;

    componentDidMount() {
        // 右侧按钮弹窗遮罩监听
        const secret = document.getElementById('my-cheat');
        // const cheatDom = document.querySelector('#my-cheat');
        const moreContainer = document.getElementById('more-container');
        this.moreContainer = moreContainer;
        const backHome = document.getElementById('back-home');
        const myShare = document.getElementById('my-share');
        const myServeCode = document.getElementById('my-serve-code');
        if (moreContainer) {
            moreContainer.onclick = e => {
                const target: any = e.target;
                if (target.id === 'more-container') {
                    moreContainer.className = 'more-container';
                }
            };
        }
        if (secret && window.location.pathname === '/zijia/book/detail') {
            secret.onclick = e => {
                if (moreContainer) moreContainer.className = 'more-container'
                
                let { origin, search, pathname } = window.location
                if (origin.indexOf('m.zuzuche')) origin.replace('m.zuzuche', 'cars.zuzuche')
                const indexOf = pathname.lastIndexOf('/')
                pathname = pathname.slice(0, indexOf) + '/cheats' + pathname.slice(indexOf)
                const url = new URL(origin + pathname + search)
                window.location.href = url.toString()
            }
        }

        if (backHome) {
            backHome.onclick = e => {
                this.handleToHome();
            };
        }

        if (myShare) {
            myShare.onclick = e => {
                this.handleShare();
            };
        }
        if (myServeCode) {
            myServeCode.onclick = e => {
                const text = document
                    .getElementById('my-serve-code-content')
                    .innerHTML.trim();
                copy(e, text);
                moreContainer && (moreContainer.className = 'more-container');
            };
        }
    }

    // eslint-disable-next-line react/sort-comp
    handleToHome = () => {
        if (isPartner()) {
            jumpLink({ url: '/' });
        } else if (isCarRentalApp()) {
            backToBlackHome({
                handle: () => jumpLink({ url: '/' }),
            });
        } else if (isOneOfMiniprograms()) {
            if (Local.get('isSelectLocation')) {
                let searchData = Local.get('CRD_ZZC_USER_LAST_SELECT') || {};
                if(Object.keys(searchData).length !== 0) {
                    miniNameSpace.postMessage({
                        data: { searchFilter: { pickup: searchData.pickup, oplace: searchData.oPlace , dropoff: searchData.dropoff, change: true, form: 'h5-search'} }
                    });
                }
                // 透传以后删除状态
                Local.remove('isSelectLocation');
            }
            miniNameSpace?.navigateBack();
        } else {
            jumpLink({ url: '/' });
        }
    };

    /* ========= 原生配置start ========= */

    handleShare = () => {
        const { shareContent, sharePhoto } = this.props;
        window.zzc &&
            zzc.call('base_share', {
                title: shareContent
                    ? shareContent
                    : `${document.title || '租租车-国内自驾'}`,
                // content: shareContent ? shareContent : `${document.title}`,
                photo: sharePhoto
                    ? sharePhoto
                    : 'https://imgcdn5.zuzuche.com/static/18/44/b4ff30400b336c46d0c9eedf8c6d508a.png!/format/jpg',
                shareUrl: `${window.location.href}`,
                path: `pages/common/webview/index?link=${encodeURIComponent(
                    window.location.href
                )}`,
                userName: 'gh_1a50e5f758ff',
                sharePlatform: ['miniProgram', 'wxtimeline', 'qq', 'qqzone', 'copy'], // 可选，不传默认支持wxsession、wxtimeline、qq、sina、copy
                success(response) {
                    this.moreContainer &&
                        (this.moreContainer.className = 'more-container');
                },
            });
    };

    /* ========= 原生配置end ========= */

    render() {
        return null;
    }
}
