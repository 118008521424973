// @ts-nocheck
import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import Icon from '../Icon';
import BottomArrow from '../BottomArrow';
import classnames from 'classnames';
import DateUitl from '@utils/date';
import noop from '@utils/noop';
import { LeftBtn, Right } from './Widget';
import { isOneOfMiniprograms } from '@utils/native';
import './styles.scss';

export default class CarListNavBar extends Component {
    static propTypes = {
        title: PropTypes.string,
        canEdit: PropTypes.bool,
    };

    static defaultProps = {
        title: document.title,
        canEdit: true,
    };

    shouldComponentUpdate(nextProps) {
        const { title, pickup } = this.props;
        return title !== nextProps.title || pickup !== nextProps.pickup;
    }

    handleOnCenter = () => {
        const { loading, onCenter } = this.props;
        if (loading) {
            return false;
        } else {
            onCenter && onCenter();
        }
    };

    render() {
        const { onLeft, onRight, canEdit = true, style = {}, ...reset } = this.props;
        return (
            <NavBar
                style={style}
                isSticky
                className="component-nav-bar-search"
                left={
                    isOneOfMiniprograms()
                        ? null
                        : (
                            <LeftBtn onClick={onLeft} />
                        )
                }
                center={
                    <CenterTitle
                        onClick={this.handleOnCenter}
                        {...reset}
                        canEdit={canEdit}
                    />
                }
                right={
                    <Right
                        onClick={onRight}
                    />
                }
            />
        );
    }
}

const CenterTitle = memo(function(props) {
    // 时间格式兼容
    const {
        onClick,
        dropoff: { landmarkName, dropTime, timeStr: dtimeStr, time: dtime },
        pickup: { landmarkName: plandmarkName, pickTime, timeStr: ptimeStr, time: ptime },
        canEdit,
        tj,
        titleStyle = {},
        arrowStyle
    } = props;
    const pickTimes = pickTime ? pickTime : ptimeStr ? ptimeStr : ptime;
    const dropTimes = dropTime ? dropTime : dtimeStr ? dtimeStr : dtime;
    const formatPickTime = DateUitl.format(pickTimes, 'MM-DD HH:mm');
    const formatDropTime = DateUitl.format(dropTimes, 'MM-DD HH:mm');
    const times = DateUitl.diff({ start: pickTimes, end: dropTimes }).times;
    const cls = classnames({
        'component-nav-bar-search-center-box': true,
        long: times.toString().length >= 6,
        mini: isOneOfMiniprograms(),
        normal: !canEdit,
    });

    return landmarkName ? (
        <div className={cls} data-tj={tj} onClick={!!canEdit ? onClick : noop} style={titleStyle}>
            <div className="search-pick-item txt-right">
                <div className="search-pick-item-text">{plandmarkName}</div>
                <div className="search-pick-item-time">{formatPickTime}</div>
            </div>
            <div className="search-day-item">
                <div className="duration">{times}</div>
                <BottomArrow arrowStyle={arrowStyle} />
            </div>
            <div className="search-pick-item">
                <div className="search-pick-item-text">{landmarkName}</div>
                <div className="search-pick-item-time">{formatDropTime}</div>
            </div>
            {!!canEdit && (
                <div className="icon-box">
                    <Icon name="Ic_driver_edit" />
                </div>
            )}
        </div>
    ) : (
        <div></div>
    );
});
