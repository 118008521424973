import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    setCarListPageTitle,
    isCarRentalApp,
} from '@utils/native';
import DateUitl from '@utils/date';
import { isEmptyObject } from '@utils/type';
import { jumpLink } from '@utils/url';
import NavBar from '../NavBar';
import RightBtn from './RightBtn';

const HEIGHT = document.documentElement.offsetHeight;

/**
 * 是否有效日期
 * TIPS⚠️：IOS对YYYY-MM-DD 格式有兼容问题 建议使用YYYY/MM/DD
 * @param {*} date
 */

function isValidDate(date) {
    return new Date(date) instanceof Date && !isNaN(new Date(date).getTime());
}

/**
 * 页面组件。满屏，内部滚动
 * 注意：依赖HTML高度
 */
interface PageCarListProps {
    defaultNavBarProps?: any
    useRightBar?: any
    children?: any
    rightBarList?: any
    style?: any,
    className?: string,
    navBar?: any,
    defaultNavBar?: any,
    shareContent?: string,
    sharePhoto?: string
}
export default class PageCarList extends React.Component<PageCarListProps> {
    static propTypes = {
        defaultNavBar: PropTypes.bool,
        defaultNavBarProps: PropTypes.shape({
            // ...NavBar.propTypes
            title: PropTypes.string,
        }),
        navBar: PropTypes.node,
        children: PropTypes.node.isRequired,
        // eslint-disable-next-line
        style: PropTypes.object,
    };

    static defaultProps = {
        defaultNavBar: true,
        defaultNavBarProps: {},
        navBar: null,
        style: {},
    };
    bodyRef: any;
    constructor(props) {
        super(props);
        this.bodyRef = React.createRef();
    }

    componentDidMount() {
        this.setTitle();
    }

    componentDidUpdate(prevProps) {
        const { condition } = this.props.defaultNavBarProps;
        if (
            JSON.stringify(condition) !==
            JSON.stringify(prevProps.defaultNavBarProps.condition)
        ) {
            this.setTitle();
        }
    }

    /* ========= 原生配置end ========= */

    setTitle() {
        const { condition } = this.props.defaultNavBarProps;
        document.title = `${condition?.pickup?.cityName || ''}租车报价`;
        const pickTimes =
            condition?.pickup?.timeStr ||
            condition?.pickup?.pickTime ||
            condition?.pickup?.time;
        const dropTimes =
            condition?.dropoff?.timeStr ||
            condition?.dropoff?.dropTime ||
            condition?.dropoff?.time;
        const formatPickTime = DateUitl.format(pickTimes, 'MM-DD HH:mm');
        const formatDropTime = DateUitl.format(dropTimes, 'MM-DD HH:mm');
        const times = DateUitl.diff({ start: pickTimes, end: dropTimes }).times;
        setCarListPageTitle({
            pickUpLocation: condition?.pickup?.landmarkName ?? '请选择取车地点',
            returnLocation: condition?.dropoff?.landmarkName ?? '请选择还车地点',
            pickUpDate: formatPickTime || '',
            returnDate: formatDropTime || '',
            titleTips: times?.indexOf('NaN') != -1 ? '' : times,
            onTouchBar: 'window["onCenterTouch"]()',
            success: function() {},
        });
    }

    render() {
        const {
            children,
            className,
            style,
            navBar,
            defaultNavBar,
            defaultNavBarProps,
            shareContent = '',
            sharePhoto = '',
            ...rest
        } = this.props;
        let targetNavBar = navBar;
        if (!targetNavBar && defaultNavBar) {
            targetNavBar = <NavBar {...defaultNavBarProps} />;
        }

        const cls = classnames(
            'component-page component-page-full-screen car-list-page',
            className
        );
        return (
            <main
                {...rest}
                className={cls}
                style={{
                    height: HEIGHT,
                    ...style,
                }}
            >
                {targetNavBar && !isCarRentalApp() && (
                    <div className="component-page-full-screen-header" id="full-header">
                        {targetNavBar}
                    </div>
                )}
                <div className="component-page-full-screen-body">{children}</div>
                <RightBtn shareContent={shareContent} sharePhoto={sharePhoto} />
            </main>
        );
    }
}
