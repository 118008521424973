/**
 * za 日志上报
 * 本地不上报
 */
// @ts-nocheck
import { isFunction, isObject } from '../type';

const za = window.za;
const ENV = process.env;
const EGG_SERVER_ENV = ENV.EGG_SERVER_ENV || 'local';

const USE_EA_LOG =
    EGG_SERVER_ENV === 'local' || EGG_SERVER_ENV === 'undefined' ? false : true;

function appendScope(scope, options = {}) {
    if (isObject(options)) {
        if (isObject(options.tag)) {
            Object.keys(options.tag).forEach((key) => {
                scope.setTag(key, options.tag[key]);
            });
        }
        if (isObject(options.extra)) {
            Object.keys(options.extra).forEach((key) => {
                scope.setExtra(key, options.extra[key]);
            });
        }
        if (isObject(options.context)) {
            Object.keys(options.context).forEach((key) => {
                scope.setContext(key, options.context[key]);
            });
        }
    }
}

export function error(errTitle, options = {}) {
    if (isFunction(za)) {
        za('sentry.withScope', (scope) => {
            appendScope(scope, options);
            za('sentry.captureException', (errTitle instanceof Error ? errTitle : new Error(errTitle)));
        });
    } else {
        console.error(errTitle, JSON.stringify(options));
    }
}

export function warn(errTitle, options = {}) {
    if (isFunction(za)) {
        za('sentry.withScope', (scope) => {
            appendScope(scope, options);
            scope.setLevel('warning');
            za('sentry.captureMessage', errTitle);
        });
    } else {
        console.warn(errTitle, JSON.stringify(options));
    }
}

export function info(errTitle, options = {}) {
    if (isFunction(za)) {
        za('sentry.withScope', (scope) => {
            appendScope(scope, options);
            scope.setLevel('info');
            za('sentry.captureMessage', errTitle);
        });
    } else {
        console.info(errTitle, JSON.stringify(options));
    }
}

export function debug(errTitle, options = {}) {
    if (isFunction(za)) {
        za('sentry.withScope', (scope) => {
            appendScope(scope, options);
            scope.setLevel('debug');
            za('sentry.captureMessage', errTitle);
        });
    } else {
        console.debug(errTitle, JSON.stringify(options));
    }
}

export { feLinkReport, apiLinkReport} from './eaLink';
