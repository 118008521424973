// @ts-nocheck
import React from 'react';

import './styles.scss';

export default function BottomArrow({ arrowStyle = {} }) {
  return (
    <div className="botton-arrow-container">
      {/* <span className="botton-arrow-line">
      </span> */}
      <span className="botton-arrow-triangle" style={arrowStyle}>
      </span>
    </div>
  );
}
