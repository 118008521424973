import { isEmptyObject, isObject } from './type';
import { isAndroid, isApp, isOneOfMiniprograms, isPartner, miniNameSpace} from './native';
import qs from 'qs';
const insuranceDomainInfo = window.__initData?.insuranceDomain || {};
import * as utilLogger from '@utils/logger';
// 通过webpack插件注入此变量。
const {
    prefix: { PREFIX_CLIENT_BASE, PREFIX_PAGE },
} = projectConfig;

const baseURL = PREFIX_PAGE;

function formatJump(link) {
    window.location.href = link;
}

function formatReplaceUrl(link) {

    window.location.replace(link);
}

/**
 * @description 页面跳转函数
 * use example
 * jumpLink({
 *  url: '/detail',
 *  params: {
 *    orderId: '1234'
 *  }
 * })
 *
 * @param {object} param 参数
 * @param {string} param.url 跳转目标链接
 * @param {object} [param.params] 链接参数
 * @param {string} [param.ZZCAppArg] app Webview加载页面的事件方法 'jsloadingicon,nowebviewslide,selfopenpage'等
 * @param {boolean} [param.noBook]
 * @param {boolean} [param.sameOrigin] 目标页面和源页面同源=>不做保险域名检查
 */

export function jumpLink({ url, params, ZZCAppArg, noBook, sameOrigin }: {url: string, params?: any, noBook?: boolean, sameOrigin?: any, ZZCAppArg?: any}) {
    formatJump(generateLink({ url, params, ZZCAppArg, noBook, sameOrigin }));
}

/**
 * @description 使用replace方式跳转
 * @param {object} param 参数
 * @param {string} param.url 跳转目标链接
 * @param {object} [param.params] 链接参数
 * @param {string} [param.ZZCAppArg] app Webview加载页面的事件方法 'jsloadingicon,nowebviewslide,selfopenpage'等
 * @param {boolean} [param.noBook]
 * @param {boolean} [param.sameOrigin] 目标页面和源页面同源=>不做保险域名检查
 */
export function jumpLinkUseReplace({ url, params, ZZCAppArg, noBook, sameOrigin }: {url: string, params?: any, noBook?: boolean, sameOrigin?: any, ZZCAppArg?: any}) {
    formatReplaceUrl(generateLink({ url, params, ZZCAppArg, noBook, sameOrigin }));
}

/**
 * @description 合并参数并生成一个url
 * @param {object} param 参数
 * @param {string} param.url 跳转目标链接
 * @param {object} [param.params] 链接参数
 * @param {string} [param.ZZCAppArg] app Webview加载页面的事件方法 'jsloadingicon,nowebviewslide,selfopenpage'等
 * @param {boolean} [param.noBook]
 * @param {boolean} [param.sameOrigin] 目标页面和源页面同源=>不做保险域名检查
 * @returns {string} 链接
 */
export function generateLink({ url, params, ZZCAppArg, noBook, sameOrigin = false }: {url: string, params?: any, ZZCAppArg?: any, noBook?: boolean, sameOrigin?: boolean}) {
    const httpReg = /^((https|http|ftp|rtsp|mms)?:\/\/)/gi;
    // 首页
    let link = PREFIX_CLIENT_BASE;

    // app环境，并且确定要加zzc方法拦截
    if (isApp() && ZZCAppArg) {
        params = {
            ...params,
            ZZCAppArg,
        };
    }

    // 不是book开头
    if (noBook) {
        return isEmptyObject(params)
            ? `${link}${url}`
            : `${link}${url}?${formatParams(params)}`;
    }
    if (!httpReg.test(url)) {
        // 非首页 拼接参数
        if (url !== link && url !== '/') {
            const bookReg = /^(\/book)/gi;
            const slashReg = /^(\/)/gi;
            // 以'/book'开头
            const book = bookReg.test(url);
            // 以'/'开头
            const slash = slashReg.test(url);

            if (!isEmptyObject(params)) {
                link = book
                    ? `${PREFIX_CLIENT_BASE}${url}?${formatParams(params)}`
                    : slash
                    ? `${baseURL}${url}?${formatParams(params)}`
                    : `${baseURL}/${url}?${formatParams(params)}`;
            } else {
                link = book
                    ? `${PREFIX_CLIENT_BASE}${url}`
                    : slash
                    ? `${baseURL}${url}`
                    : `${baseURL}/${url}`;
            }
        } else {
            link += '/';
        }
        link = handleWholeUrl(link);
        /**
         * @description: 补充为完整的url
         */
    } else {
        link = url;
    }

    // 目标页面没有同源要求，且开启了保险域名，则需要处理保险逻辑。
    if (!sameOrigin && insuranceDomainInfo.turnOn) {
        /*
        link = generateInsuranceDomainLink({
            link,
            transitLink: insuranceDomainInfo.transitLink,
            whitePaths: insuranceDomainInfo.pageList,
        });
        */
    }

    return link;
}

/**
 * @description 处理跳转保险域名。
 * @param {object} params
 * @param {string} params.link 目标链接
 * @param {string} params.transitLink 保险中转链接
 * @param {string} params.whitePaths 保险域页面路径
 * @returns {string} link
 *
 * 当前页面是正常域名时：
 * 目标页面在保险域名名单里，则需要更改link的域名为保险域名，同时通过中转页面跳转；
 * 目标页面不在保险域名名单里，不处理。
 *
 * 当前在保险域名时：
 * 目标页面在保险域名名单里，不处理；
 * 目标页面不在保险域名名单里，则需要更改域名=>变为正常的域名。
 *
 * 名词解释：
 * 正常域名指.zuzuche.com|.zuzuche.net；
 * 保险域名指.zuzucheins.com|.zuzucheins.net。
 */
export function generateInsuranceDomainLink({ link, transitLink, whitePaths }) {
    const httpReg = /^((https|http|ftp|rtsp|mms)?:\/\/)/gi;
    let currentNormalDomain = judgeNormalDomain(location.hostname);

    // 没有协议号的，补充origin
    if (!httpReg.test(link)) {
        link = location.origin + link;
    }

    const targetURL = new window.URL(link);
    // 路径: 必须包含/zijia/ => 国内业务路径
    if (!/^\/zijia\//.test(targetURL.pathname)) {
        return link;
    }

    const isInsurancePage =
        whitePaths.findIndex(_path => _path === targetURL.pathname) > -1;

    if (currentNormalDomain && isInsurancePage) {
        targetURL.hostname = convertNormalAndInsuranceDomain(targetURL.hostname);
        let params = {
            refer: targetURL.href,
        };
        link = mergeToUrl(transitLink, params, true);
    } else if (!currentNormalDomain && !isInsurancePage) {
        targetURL.hostname = convertNormalAndInsuranceDomain(targetURL.hostname);
        link = targetURL.href;
    }

    return link;
}

export function jumpLinkWithBFcache({ url, params, ZZCAppArg, noBook, sameOrigin }){
    if (isPartner()) {
      jumpLink({ url, params, ZZCAppArg, noBook, sameOrigin })
    }
    else if (isOneOfMiniprograms() && isAndroid()) {
      const link = addDomainPrefix(generateLink({ url: url, params, ZZCAppArg, noBook, sameOrigin }))
      miniNameSpace?.navigateTo({
          url: `/pages/common/webview/index?link=${encodeURIComponent(link)}`
      });
    } else {
      jumpLink({ url, params, ZZCAppArg, noBook, sameOrigin })
    }
  }

export function addDomainPrefix (url){
    if(!url)return url
    if(!/^http/.test(url)){
      url = window.origin + url
    }
    return url
  }
/**
 * @description 检测是否为正常域名。
 * @param {string} hostname
 * @returns {boolean} bool
 *
 * 测试案例
 * 127.0.0.1:7001  => 不处理
 * local.zuzuche.net:7001
 * cars.zuzuche.com
 */
function judgeNormalDomain(hostname) {
    return (
        hostname
            .split(':')[0]
            .split('.')
            .slice(-2, -1)
            .join('') === 'zuzuche'
    );
}

/**
 * @description 将正常域名和保险域名互相转换
 * @param {string} hostname
 * @returns {string} hostname
 */
function convertNormalAndInsuranceDomain(hostname) {
    let list = hostname.split(':')[0].split('.');

    if (list.length < 2) {
        return hostname;
    }

    if (list[list.length - 2] === 'zuzuche') {
        list[list.length - 2] = 'zuzucheins';
    } else if (list[list.length - 2] === 'zuzucheins') {
        list[list.length - 2] = 'zuzuche';
    }

    return list.join('.');
}
// 补充完整的url
export const handleWholeUrl = uri => {
    const { origin } = window.location;
    const httpReg = /^((https|http|ftp|rtsp|mms)?:\/\/)/gi;
    let link = uri;
    if (!httpReg.test(uri)) {
        // 完整的url
        const url = `${origin}${uri}`;
        link = url;
    }
    return link;
};

export const getQueryString = key => {
    const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`);
    const result = window.location.search.substr(1).match(reg);
    return result ? decodeURIComponent(result[2]) : null;
};

/**
 *
 * @param {object} params
 * @param {boolean} pure 是否对key和value进行encodeURIComponent。
 * @returns
 */
export const formatParams = (params, pure = true) => {
    // 先扁平化一次参数对象，以防止传入深层结构
    params = flattenObject(params);
    const arr = [];
    Object.keys(params).forEach(key => {
        if ({}.hasOwnProperty.call(params, key)) {
            if (params[key] === undefined) {
                console.error(`formatParams: 传参的键${key}的值为undefined，已丢弃`);
            } else {
                pure
                    ? arr.push(
                          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
                      )
                    : arr.push(`${key}=${params[key]}`);
            }
        }
    });

    return arr.join('&');
};

/**
 * 合并参数字符串到url上
 *
 * @param {string} url
 * @param {object} params
 * @param {boolean} pure 是否对key和value进行encodeURIComponent。
 */
export const mergeToUrl = (url, params, pure) => {
    const paramsStr = formatParams(params, pure);
    if (/\?/.test(url)) {
        url += `&${paramsStr}`;
    } else {
        url += `?${paramsStr}`;
    }
    return url;
};

export const parseSearchParams = (url = '') => {
    const params = {}
    try {
        const search = url ? new URL(url)?.search : window.location.search
        if (search) {
            search
                .substring(1)
                .split('&')
                .forEach((str) => {
                    // 防止value存在'='
                    const idx = str.indexOf('=')
                    params[str.substring(0, idx)] = decodeURIComponent(str.substring(idx + 1))
                })
        }
    } catch (error) {
        console.error('错误的url格式', error)
    }
    return params
};

export const parseQueryString = url => {
    const result = {};
    const paraString = url
        ? url
              .substring(url.indexOf('?') + 1, url.length)
              .split('&')
              .map(s => s.split('='))
        : [];

    paraString.forEach(search => {
        result[search[0]] = decodeURIComponent(search[1]);
    });
    return result;
};

export function replaceQueryString(url, params) {
    let newUrl = '';
    for (let key in params) {
        const reg = eval(`/(${key}=)([^&]*)/gi`);
        newUrl = url.replace(reg, `${key}=${params[key]}`);
        url = newUrl;
    }
    return url;
}

// 删除url种的查询字段
export function deleteQueryString(url, params) {
    params.forEach(param => {
        url = url.replace(new RegExp(`&?${param}=[^&]*`, 'g'), '');
    });
    if (/\?$/.test(url)) {
        url = url.replace('?', '');
    }
    console.log(url);
    return url;
}

export function formatReplaceParams(params = {}, pure) {
    const parseParams = pure
        ? deleteParams({
              ...parseSearchParams(),
              ...params,
          })
        : {
              ...parseSearchParams(),
              ...params,
          };
    let search = formatParams(parseParams);
    if (!!search) {
        return `?${search}`;
    }
    return '';
}

export function deleteParams(params) {
    Object.keys(params).forEach(key => {
        if ({}.hasOwnProperty.call(params, key)) {
            if (
                params[key] === 'null' ||
                params[key] === 'undefined' ||
                params[key] === null ||
                params[key] === undefined
            ) {
                delete params[key];
            }
        }
    });
    return params;
}

/**
 * 替换url
 * @param {object} params 参数对象
 * @param {boolean} pure 是否删除值为 'null' 'undefined' 的值 默认删除 ⚠️ 注意 是字符串 'undefined' 'null'！
 */
export function replaceParams(params, pure = true) {
    const url = formatReplaceParams(params, pure);
    window.history.replaceState({}, '', url);
}

/**
 * push history state
 * @param {object} params 参数对象
 * @param {string} params.state state 默认为null
 * @param {string} params.title title 默认为空 ''
 * @param {string} params.url url
 */
export function pushState({ state = null, title = '', url = '' }) {
    window.history.pushState(state, title, url);
}

/**
 * replace history state
 * @param state
 * @param title
 * @param url
 */
export function replaceState({ state = null, title = '', url = '' }) {
    window.history.replaceState(state, title, url);
}

// 获取顶级域名
export function getTopDomain() {
    const regResult = /.([A-Za-z]+)$/gi.exec(location.hostname);

    return regResult ? regResult.pop().toLowerCase() : '';
}

/**
 * 扁平化对象
 * @param {Object} deepMap
 */
function flattenObject(deepMap) {
    var result = {};
    function process(key, value) {
        if (Object(value) !== value) {
            if (key) {
                result[key] = value;
            }
        } else if (Array.isArray(value)) {
            for (var i = 0, len = value.length; i < len; i++) {
                process(key + '[' + i + ']', value[i]);
            }
            if (value.length === 0 && key) {
                result[key] = [];
            }
        } else {
            var isEmpty = true;
            for (var prop in value) {
                isEmpty = false;
                process((key ? key + '.' : key) + prop, value[prop]);
            }
            if (isEmpty && key) {
                result[key] = {};
            }
        }
    }
    process('', deepMap);
    return result;
}

/**
 * 对象反扁平化
 * 将诸如my.name: 'xxx'的属性改成my: {name: 'xxx'}
 * @param {Object} data
 */
function unFlattenObject(data) {
    if (Object(data) !== data || Array.isArray(data)) {
        return data;
    }
    var result = {};
    var r = /\.?([^\.\[\]]+)|\[(\d+)\]/g;
    for (var prop in data) {
        var matchs;
        var cur = result;
        var p = '';
        while ((matchs = r.exec(prop))) {
            cur = cur[p] || (cur[p] = matchs[2] ? [] : {});
            p = matchs[2] || matchs[1];
        }
        cur[p] = data[prop];
    }
    return result[''] || result;
}

export function getUrlParam(url = '') {
    const obj: any = {};
    const urlArr = url.split('?');
    obj.protocol = urlArr[0].split('://')[0];
    obj.url = urlArr[0].split('://')[1];
    const result = urlArr[1].split('&');
    result.forEach(item => {
        const iArr = item.split('=');
        obj[iArr[0]] = iArr[1];
    });
    return obj;
}

export function query(key, customedUrl) {
    let searchStr = window.location.search.slice(1);
    let query = {};

    if (customedUrl) {
        searchStr = customedUrl.slice(customedUrl.indexOf('?') + 1);
    }

    // 根据key获取对应的value
    if (key) {
        let obj = {};
        searchStr.split('&').forEach(val => {
            obj[val.substr(0, val.indexOf('='))] = val.substr(val.indexOf('=') + 1);
        });

        let value = obj[key] ? obj[key] : '';

        // 打补丁，大于等于最大安全整型的数字返回字符串型
        return value === '' || isNaN(value) || !Number.isSafeInteger(value)
            ? value
            : value - 0;
    }

    // 把整个地址序列化成一个对象
    searchStr.split('&').forEach(function(str) {
        if (str) {
            let ary = str.split('=');
            if (ary && ary.length > 0) query[ary[0]] = ary[1];
        }
    });

    return query ? query : '';
}

/**
 * 从search字符串中删除blackList中的字段
 * @param {string[]} blackList 黑名单字段
 * @param {string | {key: string: string}} search 搜索字符串或者对象
 * @returns string
 */
export function removeSearchParams(blackList = [], search = location.search) {
    const searchParams = qs.parse(
        search == location.search
            ? search.replace(/^\?/, '')
            : search
    );

    blackList?.forEach(key => {
        delete searchParams[key]
    });
    return qs.stringify(searchParams);
}

/**
 * 从search字符串中抽离whiteList中的字段
 * @param {string[]} whiteList 白名单字段
 * @param {string | {key: string: string}} search 搜索字符串或者对象
 * @returns string
 */
export function extractSearchParams(whiteList = [], search = location.search) {
    const searchParams = qs.parse(search);

    for (const [k, v] of Object.entries(searchParams)) {
        if (!whiteList.includes(k)) {
            delete searchParams[k];
        }
    }
    return qs.stringify(searchParams);
}
