// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import { Title } from './Widget';

export default class OnlyTitle extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        isSticky: PropTypes.bool,
    };

    static defaultProps = {
        title: document.title,
        isSticky: false,
    };

    shouldComponentUpdate(nextProps) {
        const { title } = this.props;
        return title !== nextProps.title;
    }

    render() {
        const { title, isSticky } = this.props;

        return (
            <NavBar
                isSticky
                className="component-nav-bar-default"
                center={<Title title={title} />}
            />
        );
    }
}
