// @ts-nocheck
import zzc from '../zzc';
import Detector from '../detector';
import { getUserId, getPartnerId } from '../cookie';
import { jumpLink, mergeToUrl } from '../url';
import { versionCompare } from '../tools';
import DateUitl from '../date';
import * as utilLogger from '@/utils/logger';
import { sleep } from '../noop';

// 租租车host。
const defaulHost = 'https://m.zuzuche.net';
const prodHost = 'https://m.zuzuche.com';
const netHost = 'https://m_cn_rental.zuzuche.net';

const env = process.env.EGG_SERVER_ENV;
const { protocol, hostname, port } = window.location;

// 除正是环境使用.com外，其他环境均跳转值.net
const isProd = hostname.includes('.com');
export const baseHost = isProd ? prodHost : defaulHost;
export const orderHost = isProd ? prodHost : defaulHost;
/**
 * 是否为原生应用
 */
export function isApp() {
    return (
        Detector.browser.black ||
        Detector.browser.zuzuche ||
        Detector.browser.crc ||
        Detector.browser.rewards ||
        Detector.browser.tantucrc ||
        Detector.browser.cgb
    );
}

/**
 * @desc 是否为租车的App（与业务相关）
 */
export function isCarRentalApp() {
    return (
        Detector.browser.zuzuche ||
        Detector.browser.crc ||
        Detector.browser.rewards ||
        Detector.browser.tantucrc
    );
}

/**
 * 用于区分原生应用是租租车还是骑士卡app
 * 是否为租租车app
 */
export function isZZCApp() {
    return Detector.browser.zuzuche;
}

/**
 * 是否为骑士卡app
 */
export function isBlackApp() {
    return Detector.browser.black;
}
/**
 * 是否为国内租租车app
 */
export function isCrcApp() {
    return Detector.browser.crc;
}

export function isRewardsApp() {
    return Detector.browser.rewards;
}

export function isTantuCrcApp() {
    return Detector.browser.tantucrc;
}

/**
 * 新接入的分销平台
 */
export function isPartner() {
    return isGzlMini() || isCgbApp();
}
// 广之旅分销
export function isGzlMini() {
    return isMiniprogram() && getPartnerId() == 'J18645910';
}
// 广发分销
export function isCgbApp() {
    return Detector.browser.cgb && getPartnerId() == 'K12760208';
}
// 民生分销-仅对接联登(接入方是国际)
export function isMsApp() {
    return Detector.browser.ms && getPartnerId() == 'B26280409';
}

/**
 * 是否IOS
 */
export function isIos() {
    return Detector.os.ios;
}

/**
 * 是否android
 */
export function isAndroid() {
    return Detector.os.android;
}

export function isAlipay() {
    return Detector.browser.alipay;
}

export function isWeiXin() {
    return Detector.browser.weixin;
}

// 微信小程序
export function isMiniprogram() {
    return Detector.browser.miniprogram;
}
// 支付宝小程序
export function isAliPayMiniprogram() {
    return ['alipaymini', 'quarkmini', 'ucmini'].some(n => n in Detector.browser);
}

// 字节跳动小程序
export function isByteDanceMiniprogram() {
    return Detector.browser.toutiaomicroapp;
}

export function isBaiduMini() {
    return Detector.browser.baidumini || Detector.browser.baidumini_native;
}

export function isJdMiniprogram() {
    return Detector.browser.jdmini;
}

// 是小程序
export function isOneOfMiniprograms() {
    return [
        'miniprogram',
        'alipaymini',
        'quarkmini',
        'ucmini',
        'toutiaomicroapp',
        'baidumini',
        'baidumini_native',
        'jdmini',
    ].some(c => Detector.browser.hasOwnProperty(c));
}

// 是哪个小程序 返回小程序的name
export function whichOneMiniprogram() {
    return [
        'miniprogram',
        'alipaymini',
        'quarkmini',
        'ucmini',
        'toutiaomicroapp',
        'baidumini',
        'baidumini_native',
        'jdmini',
    ].filter(c => Detector.browser.hasOwnProperty(c))[0];
}

// 快应用
export function isQuickApp() {
    return Detector.browser.name.indexOf('quickapp') > -1;
}
// 华为快应用
export function isHuaweiQuickApp() {
    return Detector.browser.quickapp_huawei;
}

// 华为自带浏览器
export function isHuwaweiBrowser() {
    return Detector.browser.huwawei;
}

// 是快应用
export function isOneOfQuickApp() {
    return isQuickApp();
}
/**
 * @desc 探测App是否支持某插件
 */
export function isAppSupportPlugin(pluginName) {
    if (pluginName == null || pluginName == undefined || '') {
        return false;
    }
    return !!window.zzcjsbridge && !!window.zzcjsbridge[pluginName];
}

/**
 * 小程序jssdk注入global的命名空间
 */
export const miniNameSpace = (() => {
    if (isMiniprogram() && window?.wx) {
        if (wx?.miniProgram) {
            const wxProxy = Object.create(wx.miniProgram);
            // 貌似要这样处理才生效
            wxProxy.navigateTo = async function(...args) {
                await sleep(300);
                return wx.miniProgram.navigateTo(...args);
            };
            return wxProxy;
        }
        return wx?.miniProgram;
    } else if (isAliPayMiniprogram() && window?.my) {
        return my;
    } else if (isByteDanceMiniprogram() && window?.tt) {
        return tt?.miniProgram;
    } else if (isJdMiniprogram() && window?.jd) {
        return jd?.miniProgram;
    } else if (isBaiduMini() && window?.swan) {
        return swan?.webView;
    }
})();

/**
 * 用于连续跳转多个小程序原生页面。
 * 组装链式跳转链接，并执行小程序jssdk的方法，
 * 把组装链接作为参数拼接到url上。
 * 连续跳转的具体逻辑见miniprogram项目的/pages/landing/index
 *
 * 例:
 * ```javascript
 * new CombineNavigateChain()
 *   .navigateTo({
 *      url: '/pages/pay/index?justsometest=1'
 *    })
 *   .navigateTo({
 *      url: '/pages/webview/index?something=anotherthing'
 *    })
 *   .launch()
 * ```
 * 执行launch后，小程序就会先 navigateTo /pages/landing/index
 * 由/pages/landing/index redirectTo /pages/pay/index?justsometest=1，
 * 然后再次 navigateTo /pages/landing/index
 * 由/pages/landing/index redirectTo /pages/webview/index?something=anotherthing
 */
export class CombineNavigateChain {
    constructor() {
        this.stack = [];
        // TODO: 支持navigateBack
        ['redirectTo', 'navigateTo', 'switchTab', 'reLaunch'].forEach(action => {
            this[action] = options =>
                this.combine({
                    action,
                    options,
                });
        });
    }
    combine({ action, options }) {
        this.stack.push({
            action,
            options,
        });
        return this;
    }
    /**
     * 利用动态规划思想，
     * 每一步都由上一步的参数拼装而成，
     * 最终[1，len -1]的成员都会拼装成同一条链接，执行第0个成员的方法，并把封装的链接拼装到url上
     * afterAction 跳转到页面后采取的路由操作, 如navigateTo、switchTab
     * afterActionOptions 传给afterAction的options
     */
    launch() {
        const resultArr = [];

        this.stack.reverse();
        this.stack.forEach(({ options = {}, action }, i) => {
            const { url, ...restOptions } = options;

            resultArr.push(
                i
                    ? {
                          action,
                          options: {
                              ...(url && {
                                  url: mergeToUrl('/pages/common/webview/index', {
                                      link: url,
                                      afterAction: resultArr[i - 1].action,
                                      afterActionOptions: JSON.stringify(
                                          resultArr[i - 1].options
                                      ),
                                  }),
                              }),
                              ...restOptions,
                          },
                      }
                    : {
                          action,
                          options: {
                              ...(url && {
                                  url: mergeToUrl('/pages/common/webview/index', {
                                      link: url,
                                  }),
                              }),
                              ...restOptions,
                          },
                      }
            );
        });
        this.stack = [];
        const targetMethod =
            resultArr.length &&
            resultArr[resultArr.length - 1].action &&
            miniNameSpace[resultArr[resultArr.length - 1].action];

        if (targetMethod) {
            targetMethod(resultArr[resultArr.length - 1].options);
        }
    }
}

/**
 * 登陆处理函数封装，包括原生和H5登陆
 * 由于ios和安卓兼容问题 使用/login/v2sourceUrl和/login/?sourceUrl，在调用onLoginStatusChange监听时
 * 得到的gourl会表现不一致，为了ios和安卓的表现一致性，不采用推荐的/v2
 * @param {string} uri 登陆后需要返回的页面uri，用于登陆返回
 */
export function handleLogin(uri = location.href) {
    const url = generateLink(uri);
    let login = `${baseHost}/account/login.php?goback=1`;
    if (isCgbApp()) {
        // 广发银行
        window.location.href = `${
            window.location.origin
        }/zijia/book/partner-land?type=login&from=crc&partnerId=${getPartnerId()}&ref=${encodeURIComponent(
            url
        )}`;
    } else if (isCarRentalApp()) {
        login = `${login}&ref=${url}`;
        window.zzc &&
            zzc.call('page_linkTo', {
                url: `/login/v2/?sourceUrl=${encodeURIComponent(
                    `${login}`
                )}&sourceBusiness=crcDiscount`,
                success: function() {},
            });
    } else if (isOneOfMiniprograms()) {
        // 兼容百度小程序
        let link = '/pages/common/login/index';
        miniNameSpace?.navigateTo({
            url: `${link}?goback=${encodeURIComponent(`${url}`)}`,
        });
    } else {
        window.location.href = `${login}&ref=${encodeURIComponent(url)}`;
    }
}

export function handleToAccount() {
    if (hasLogin()) {
        window.location.href = `${baseHost}/v2/?m=account`;
    } else {
        handleLogin(`${baseHost}/v2/?m=account`);
    }
}

export function handleToOrder(param = {}) {
    if (hasLogin()) {
        jumpLink({
            url: `${orderHost}/account/order.php?tabs=crc`,
        });
    } else {
        handleLogin(`${orderHost}/account/order.php?tabs=crc`);
    }
}

/**
 * @desc 回到首页兼容多端
 */
export function handleBackToHome() {
    if (isPartner()) {
        jumpLink({
            url: '/',
        });
    } else if (isApp()) {
        backToBlackHome({
            handle: () => jumpLink({ url: '/' }),
        });
    } else if (isOneOfMiniprograms()) {
        miniNameSpace?.switchTab({
            url: `/pages/home/index`,
        });
    } else {
        jumpLink({
            url: '/',
        });
    }
}

/**
 * @desc 静默获取设备的经纬度。
 * @param {Booleann} silent 是否静默获取
 */
export async function handleGetLatitudeAndLongitude(silent = true) {
    const LOG_PREFIX = '[ handleGetLatitudeAndLongitude ]';
    try {
        if (isApp() && !isCgbApp()) {
            const LocationPermission = await getAppPermission('Location');
            console.log(LOG_PREFIX, 'LocationPermission =', LocationPermission);
            if ((silent && LocationPermission === 2) || !silent) {
                const location = await getAppLocation();
                return location;
            }
        } else if (isMiniprogram()) {
            console.log(LOG_PREFIX, 'isMiniprogram，微信小程序无法做到静默获取，放弃。');
            // const location = await getWXLocation();
            // console.log(LOG_PREFIX, "location = ", location);
            // return location;
        } else {
            console.warn(LOG_PREFIX, '非App,非微信小程序');
        }
    } catch (err) {
        console.warn(LOG_PREFIX, err);
        utilLogger.error(`${LOG_PREFIX}${err.message}`, {
            extra: {
                error: JSON.stringify(err),
            },
        });
    }
    return null;
}

/**
 * @desc 获取App的权限
 * @param {String} permission
 * @returns {Number} per 0：没有获取过该权限；1：用户拒绝；2：允许
 */
export function getAppPermission(permission) {
    return new Promise((resolve, reject) => {
        if (!permission) {
            reject('permission 参数不应为空');
        }
        if (!isApp()) {
            reject('非App');
        }
        if (!zzc && zzc) {
            reject('未加载app jssdk');
        }

        window.zzc &&
            zzc.call('base_getAppPermission', {
                permission,
                success: response => {
                    resolve(response.data);
                },
                error: err => {
                    resolve(0);
                },
            });
    });
}
/**
 * @desc 获取App的位置信息
 */
export function getAppLocation() {
    return new Promise((resolve, reject) => {
        if (!isApp()) {
            reject('非App');
        }
        if (!zzc && zzc) {
            reject('未加载app jssdk');
        }
        window.zzc &&
            zzc.call('base_requestLocation', {
                success: response => {
                    console.warn('[getAppLocation]', 'response=', response);
                    if (response.state == 0) {
                        resolve({
                            lat: response.data.lat,
                            lng: response.data.lng,
                            accuracy: response.data.accuracy,
                        });
                    } else if (response.state == 1) {
                        reject('没有权限');
                    } else {
                        reject('定位失败');
                    }
                },
                error: err => {
                    reject(err);
                },
            });
    });
}
/**
 * @desc 获取微信的位置信息；tips: 需要在wx.config的jsApiList注册后才可使用。
 */
export function getWXLocation() {
    return new Promise((resolve, reject) => {
        if (!wx) {
            reject('未加载wx jssdk');
        }
        wx.getLocation({
            type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function(response) {
                resolve({
                    lat: response.latitude, // 纬度，浮点数，范围为90 ~ -90
                    lng: response.longitude, // 经度，浮点数，范围为180 ~ -180。
                    accuracy: response.accuracy, // 位置精度
                    speed: response.accuracy, // 速度，以米/每秒计
                });
            },
        });
    });
}
/**
 * 返回,识别原生和h5
 */
export function navBack() {
    if (isApp() && !isCgbApp()) {
        // app
        window.zzc &&
            zzc.call('BackToPreviousPage', {
                success(o) {
                    console.log(o);
                },
            });
    } else {
        // h5
        window.history.go(-1);
    }
}

/**
 * 设置当前页APP标题
 * @param {*} title 标题
 * @param {*} options 选项
 */
export function setTitle(title = '境内租车', options = {}) {
    if (isApp() && !isCgbApp()) {
        options = { ...options, title };
        window.zzc && zzc.call('webviewtitle', options);
    }
}

/**
 * 设置当前页APP右侧按钮
 * @param {object} options 按钮选项
 */
export function setRightButton(options) {
    if (isApp() && !isCgbApp()) {
        window.zzc &&
            zzc.call('base_customNavbarRightButton', {
                ...options,
            });
    }
}

/**
 * 隐藏右侧按钮
 * @param {*} options
 */
export function hideRightButton() {
    if (isApp() && !isCgbApp()) {
        window.zzc &&
            zzc.call('base_customNavbarRightButton', {
                type: 'text',
                text: '  ',
                success: function(response) {
                    console.log(response);
                },
            });
    }
}

/**
 * 设置列表页标题栏
 * 目前仅租租车app支持
 * @param {*} options
 */
export function setCarListPageTitle(options) {
    if (isCarRentalApp()) {
        window.zzc &&
            zzc.call('CarListPageTitle', {
                ...options,
            });
    }
}

export function hideTitle() {
    if (isApp() && !isCgbApp()) {
        window.zzc &&
            zzc.call('base_hideTopNavbar', {
                success: function(response) {
                    console.log(response);
                },
            });
    }
}

/**
 * 点击左侧按钮回退键监听函数
 * 目前骑士卡基本确定无效 在租租车上却是正常的
 * @param {*} options
 */
export function handleLeftClickBack(options) {
    if (isCarRentalApp()) {
        // 请注意：IOS下必须要有Type参数，否则整个插件调用不会成功
        // 同时对应的Type后必须有对应的属性，比如type为image时，必须要有imageUrl
        // 否则listener函数也不会成功😢😢😢
        window.zzc &&
            zzc.call('base_customNavbarLeftButton', {
                type: 'image',
                imageUrl: 'back',
                listener: function() {
                    options.cb && options.cb();
                },
                success: function(response) {
                    console.log(response);
                },
            });
    }
}

function generateLink(uri) {
    const httpReg = /^((https|http|ftp|rtsp|mms)?:\/\/)/gi;
    let link = uri;
    if (!httpReg.test(uri)) {
        // 完整的url
        const url =
            env === 'local' || env === 'undefined'
                ? `${protocol}//${hostname}:${port}${uri}`
                : `${protocol}//${hostname}${uri}`;
        link = url;
    }
    return link;
}

// 分享
function share(options) {
    console.log(options);
    return window.zzc && zzc.call('share', options);
}

// 分享到小程序
export function shareToWx({
    title = '',
    content = '',
    photo = '',
    shareUrl = '',
    userName = 'gh_1a50e5f758ff',
    path = '',
}) {
    share({
        title,
        content,
        photo,
        userName,
        path,
        shareUrl,
        sharePlatform: ['miniProgram', 'wxtimeline'],
        success: function(response) {
            console.log(response);
        },
    });
}

// 检测是否安装某应用
export function checkPhoneIsExistApp(appName) {
    if (isApp() && !isCgbApp()) {
        console.log('app内' + appName);
        return new Promise(res => {
            window.zzc &&
                zzc.call('base_phoneIsExistApp', {
                    appName: appName, // android 传包名 ，ios 用协议头
                    success: function(response) {
                        res(response.data);
                    },
                });
        });
    }
}

/**
 * 底部弹窗
 * options：数组，titles
   cancelButtonIndex：可选，取消按钮index
   destructiveButtonIndex：可选，红色按钮index
   title：标题
 */
export function handleShowActionSheet(options) {
    if (isApp() && !isCgbApp()) {
        window.zzc &&
            zzc.call('base_showNativeActionSheet', {
                options: options.titleArr,
                cancelButtonIndex: options.optionsArr.length,
                destructiveButtonIndex: options.optionsArr.length,
                title: '请选择地图',
                success: function(response) {
                    options.optionsArr.forEach((item, i) => {
                        if (i === response.data) {
                            window.location.href = item;
                        }
                    });
                },
            });
    }
}

export function getAppVersion() {
    return new Promise(resolve => {
        if (isApp() && !isCgbApp()) {
            window.zzc &&
                zzc.call('getAppVersion', {
                    success: function(response) {
                        resolve(response.data);
                    },
                });
        } else {
            resolve('0.0.0');
        }
    });
}

/**
 * 根据UA同步获取App版本
 */
export function getAppVersionByUa() {
    return Detector.browser.fullVersion;
}

/**
 * 页面退场操作
 * @param {object} options 配置项
 * @param {object} options.appOptions 使用原生后退配置项
 * @param {object} options.webOptions 使用h5后退配置项
 * @param {func} options.webOptions.handler 使用h5后退的处理句柄
 */

export async function handleBackToPrevious(options = {}) {
    const { appOptions, webOptions, useStep } = options;
    if (isApp() && !isCgbApp()) {
        try {
            // app原生返回
            const version = await getAppVersion();

            if (useStep) {
                // 骑士卡ios传正值
                if (
                    isBlackApp() &&
                    Detector.os.ios &&
                    (version === '1.15.0' || version === '1.15.1' || version === '1.15.2')
                ) {
                    appOptions.step = Math.abs(appOptions.step);
                }
                window.zzc &&
                    zzc.call('base_goBackPage', {
                        // 返回n页
                        ...appOptions,
                    });
                return;
            } else {
                window.zzc &&
                    zzc.call('base_backToPreviousPage', {
                        // 返回上一页
                        ...appOptions,
                    });
                return;
            }
        } catch (error) {
            // 如果原生插件报错，则使用H5方法返回
            // h5处理句柄
            if (webOptions && webOptions.handler) {
                webOptions.handler();
            } else if (window.history.length > 1) {
                window.history.go(-1);
            } else {
                window.location.href = '/zijia/';
            }
        }
    } else {
        // h5处理句柄
        if (webOptions && webOptions.handler) {
            webOptions.handler();
        } else if (window.history.length > 1) {
            window.history.go(-1);
        } else {
            window.location.href = '/zijia/';
        }
    }
}

/**
 * 设置标题栏右侧按钮菜单
 * @param {*} options
 */
export function setAppRightBarList(options = {}) {
    if (isApp() && !isCgbApp()) {
        window.zzc &&
            zzc.call('appCustomRightBarList', {
                ...options,
            });
    }
}

/**
 * 下拉刷新
 * @param {function} callback
 */
export async function pullToRefresh(callback) {
    if (isApp() && !isCgbApp()) {
        async function handler(response) {
            try {
                return await callback(response);
            } catch (e) {
                throw e;
            } finally {
                window.zzc && zzc.call('base_finishPullToRefresh');
            }
        }

        window.zzc &&
            zzc.call('base_addPullToRefresh', {
                endPull: handler,
                listener: handler,
            });
    }
}

/**
 * 返回app首页
 * @param {*} handle
 */
export function backToBlackHome(options = {}) {
    const { tabIndex = 'home', handle } = options;
    if (isApp() && !isCgbApp()) {
        try {
            if (isZZCApp()) {
                // 租租车原生首页
                window.zzc &&
                    zzc.call('page_linkTo', {
                        url: '/main/index/crc',
                        success: function() {},
                    });
                return;
            }
            window.zzc &&
                zzc.call('base_backToHome', {
                    tabIndex,
                    success: function(response) {
                        console.log(response);
                    },
                });
        } catch (error) {
            handle && handle();
        }
    } else {
        handle && handle();
    }
}

/**
 * 是否已登陆
 */
export function hasLogin() {
    return getUserId() ? true : false;
}

/**
 * 手势控制
 * @param {*} options
 */

export function handleControlLeftPanGesture(options = {}) {
    if (isApp() && !isCgbApp()) {
        window.zzc &&
            zzc.call('base_controlLeftPanGesture', {
                off: options.off || 1,
                listener: options.listener || function(rs) {}, //当off==3时候有效
                success: options.success || function(response) {},
            });
    }
}

/**
 * 关闭下拉刷新 => 只对android起作用
 */
export function handleRemovePullToRefresh() {
    if (isApp() && !isCgbApp()) {
        window.zzc &&
            zzc.call('base_removePullToRefresh', {
                success: function(response) {},
            });
    }
}

export async function handleLogout(jmmp, jump_url) {
    function webLogout(jmmp, jump_url) {
        if (jmmp) {
            jumpLink({
                url: jump_url,
            });
        }
    }
    function appLogout() {
        window.zzc &&
            zzc.call('logout', {
                success: function(response) {
                    window.zzc &&
                        zzc.call('base_backToHome', {
                            tabIndex: 'account',
                            success: function(res) {
                                console.log(res);
                            },
                        });
                },
            });
    }
    if (isCrcApp()) {
        const version = await getAppVersion();
        if (versionCompare(version, '1.0.1', 3) === 1) {
            appLogout();
        } else {
            webLogout(jmmp, jump_url);
        }
    } else if (isTantuCrcApp() || isZZCApp()) {
        appLogout();
    } else {
        webLogout(jmmp, jump_url);
    }
}

/**
 *
 * @param {*} callback
 * 描述：监听原生登陆状态的改变，

type: 0 : 退出登陆, 1:登陆* userId：用户id
* isLogined：是否登陆
* gourl：referurl
 */
export async function listenerAppLoginStatus(callback) {
    if (isCarRentalApp()) {
        window.zzc &&
            zzc.call('onLoginStatusChange', {
                success: function(res) {
                    console.log(res);
                },
                listener: function(response) {
                    callback(response);
                },
            });
    }
}

/**
 * @description：由App跳转到微信小程序中
 * @param {*} userName：拉起的小程序的userName，必填
 * @param {*} path：拉起小程序页面的路径，不填默认拉起小程序首页
 * @param {*} miniProgramType：拉起小程序的类型，0：正式，1：开发，2：体验，默认0
 * @param {*} extMsg: APP传递给微信小程序的额外数据，要求json格式，选填，视需求而定
 */
export async function openWXMiniProgram(props) {
    const { userName, path, miniProgramType, extMsg, success, listener } = props;
    if (isCarRentalApp()) {
        window.zzc &&
            zzc.call('openWXMiniProgram', {
                userName,
                path,
                miniProgramType,
                extMsg,
                success:
                    success ||
                    function(res) {
                        console.log(res);
                    },
                listener:
                    listener ||
                    function(response) {
                        console.log(response);
                    },
            });
    }
}

/**
 * 日历组件，仅国内租车有
 */
export function handleShowNativeCalendar(options = {}, handle) {
    try {
        const minTimestamp =
            options.minTimestamp || DateUitl.ToTimeStamp(DateUitl.addTime(1, 'hour'));
        const maxTimestamp =
            options.maxTimestamp || DateUitl.ToTimeStamp(DateUitl.addTime(90, 'day'));
        window.zzc &&
            zzc.call('book_calendar', {
                minTimestamp: minTimestamp, //时间戳，单位毫秒（日历开始的时间，比如今天。2020.1.3）
                maxTimestamp: maxTimestamp, //时间戳，单位毫秒（日历结束的时间，比如最多180天。2020.7.3）
                pickUpTimestamp: options.pickUpTimestamp, //时间戳，单位毫秒（用户选的取车时间）
                dropOffTimestamp: options.dropOffTimestamp, //时间戳，单位毫秒（用户选的还车时间）
                pickUpMaxSelectedSpace: options.pickUpMaxSelectedSpace, //可选最大取车天数，距离minTimestamp多少天（取车最大多少天，现在是180天）
                pickUpAndDropOffMaxDays: options.pickUpAndDropOffMaxDays, //取还车最大租期，（取还车最大租期，现在是90天）
                pickUpAndDropOffMinHours: options.pickUpAndDropOffMinHours, //取还车之间最小间隔，小时（取还车最小间隔多少小时，比如，现在是1小时）
                landmarkPrompt: options.landmarkPrompt, //提示（提示语，比如，现在是“取还车时间在夜间或凌晨可能会被收取夜间服务费”）
                success: options.success,
            });
    } catch (err) {
        handle && handle();
    }
}

/**
 * 地标&时间选择插件，国际租车App和国内租车App（准备接入）
 */
export function handleAppDateSettingTool(options = {}, handle) {
    if (!isAppSupportPlugin('crcRentCarSettingAlert')) {
        return false;
    }

    const minTimestamp =
        options.minTimestamp || DateUitl.ToTimeStamp(DateUitl.addTime(1, 'hour'));
    const maxTimestamp =
        options.maxTimestamp || DateUitl.ToTimeStamp(DateUitl.addTime(90, 'day'));

    // 字段信息详见；http://wiki.int.zuzuche.info/pages/viewpage.action?pageId=7668275
    window.zzc &&
        zzc.call('crcRentCarSettingAlert', {
            pickUpCityId: options.pickUpCityId, //取车城市id
            pickUpCityName: options.pickUpCityName, //取车城市名称
            pickUpLandmarkId: options.pickUpLandmarkId, //取车地标id
            pickUpLandmarkName: options.pickUpLandmarkName, //取车地标名称
            pickUpLandmarkLat: options.pickUpLandmarkLat, //如果id为-1时，为谷歌地标，传取车地标经纬度
            pickUpLandmarkLng: options.pickUpLandmarkLng, //如果id为-1时，为谷歌地标，传取车地标经纬度
            pickUpTimeStamp: options.pickUpTimeStamp, // 取车时间戳
            dropOffCityId: options.dropOffCityId, //还车城市id
            dropOffCityName: options.dropOffCityName, //还车城市名称
            dropOffLandmarkId: options.dropOffLandmarkId, //还车地标id
            dropOffLandmarkName: options.dropOffLandmarkName, //还车地标名称
            dropOffLandmarkLat: options.dropOffLandmarkLat, //如果id为-1时，为谷歌地标，传还车地标经纬度
            dropOffLandmarkLng: options.dropOffLandmarkLng, //如果id为-1时，为谷歌地标，传还车地标经纬度
            dropOffTimeStamp: options.dropOffTimeStamp, // 还车时间戳

            minTimestamp: minTimestamp,
            maxTimestamp: maxTimestamp,
            pickUpMaxSelectedSpace: '90',
            pickUpAndDropOffMaxDays: '90',
            pickUpAndDropOffMinHours: '1',
            pickUpAndDropOffDefaultHours: '48', // 默认取还车时间间隔，两天。
            diffPlace: options.diffPlace,

            actionType: options.actionType,
            // pickUpMinHour: string,
            // pickUpMaxHour: string,
            // dropOffMinHour: string,
            // dropOffMaxHour: string,
            listener: function(response) {
                // 时间戳毫秒转为秒
                if (
                    !Number.isNaN(response.data.pickUpTimeStamp) &&
                    `${response.data.pickUpTimeStamp}`.length > 10
                ) {
                    response.data.pickUpTimeStamp =
                        Number(response.data.pickUpTimeStamp) / 1000;
                }
                if (
                    !Number.isNaN(response.data.dropOffTimeStamp) &&
                    `${response.data.dropOffTimeStamp}`.length > 10
                ) {
                    response.data.dropOffTimeStamp =
                        Number(response.data.dropOffTimeStamp) / 1000;
                }

                //点击取车城市和还车城市回调
                handle && handle(response);
            },
        });
    return true;
}

export { onPageShow, offPageShow } from './page-show-event';

export { onPageHide, offPageHide } from './page-hide-event';

/**
 * @description: 跳转首页
 * @param {*}
 */
export function handleToHome() {
    if (isPartner()) {
        jumpLink({ url: '/' });
    } else if (isApp()) {
        backToBlackHome({
            tabIndex: isBlackApp() ? 'vip' : 'home',
            handle: () => jumpLink({ url: '/' }),
        });
    } else if (isOneOfMiniprograms()) {
        miniNameSpace?.reLaunch({
            url: '/pages/home/index',
        });
    } else {
        jumpLink({ url: '/' });
    }
}

/**
 * @description: 判断当前使用是PC(PC模拟手机)还是移动端
 * 写该方法的目的：列表进详情页面的时候，判断为浏览器就新打开一个页面。
 * 在PC模拟移动端时，打开新的页面会进入PC端，无法自动跳到M版，测试起来比较麻烦，所以写了该函数判断是否PC平台
 * 目前只找到该方法来判断，即使它要被遗弃了。后续有同学知道如何处理这种情况，麻烦指教一下。
 * @param {*}
 */
export function isPC() {
    const isWin = window?.navigator?.platform.indexOf('Win');
    const isMac = window?.navigator?.platform.indexOf('Mac');
    if (isMac != -1 || isWin != -1) {
        return true;
    } else {
        return false;
    }
}

/**
 * 销毁隐藏当前App加载的Webview容器
 */
export function hideWebActivity() {
    window.zzc && zzc.call('hideWebActivity', {});
}
/**
 * 创建加载Webview容器
 */
export function showWebActivity({ url, showOn = 'home' } = {}) {
    window.zzc &&
        zzc.call('showWebActivity', {
            url,
            showOn,
        });
}

/**
 * 通知app当前webview容器加载完成
 */
export function webActivityPageFinished() {
    window.zzc && zzc.call('webActivityPageFinished', {});
}

export function isMiniPath(url) {
    return /^\/?pages\//.test(String(url)) || /^\/?submodules\//.test(String(url));
}
