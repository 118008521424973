import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    setCarListPageTitle,
    isZZCApp,
    isCrcApp,
    isRewardsApp,
    isTantuCrcApp,
    isCarRentalApp,
} from '@utils/native';
import DateUitl from '@utils/date';
import { isEmptyObject } from '@utils/type';

import NavBar from '../NavBar';

const HEIGHT = document.documentElement.offsetHeight;

/**
 * 页面组件。满屏，内部滚动
 * 注意：依赖HTML高度
 */
interface PageCarListProps {
    defaultNavBarProps?: any
    useRightBar?: any
    children?: any
    rightBarList?: any
    style?: any,
    className?: string,
    navBar?: any,
    defaultNavBar?: any
}
export default class PageCarList extends React.Component<PageCarListProps> {
    static propTypes = {
        defaultNavBar: PropTypes.bool,
        defaultNavBarProps: PropTypes.shape({
            // ...NavBar.propTypes
            title: PropTypes.string,
        }),
        navBar: PropTypes.node,
        children: PropTypes.node.isRequired,
        // eslint-disable-next-line
        style: PropTypes.object,
    };

    static defaultProps = {
        defaultNavBar: true,
        defaultNavBarProps: {},
        navBar: null,
        style: {},
    };
    bodyRef: any;
    constructor(props) {
        super(props);
        this.bodyRef = React.createRef();
    }

    componentDidMount() {
        this.setTitle();
        this.bodyRef.current.addEventListener(
            'touchmove',
            e => {
                e && e.preventDefault();
            },
            {
                passive: false, //  禁止 passive 效果
            }
        );
    }

    componentDidUpdate(prevProps) {
        const { defaultNavBarProps } = this.props;
        const { title, pickup, dropoff } = defaultNavBarProps;

        if (
            title !== prevProps.defaultNavBarProps.title ||
            (!isEmptyObject(pickup) && !isEmptyObject(dropoff))
        ) {
            this.setTitle();
        }
    }

    setTitle() {
        const { defaultNavBarProps } = this.props;
        const {
            title,
            dropoff: { landmarkName, dropTime, time: dtime },
            pickup: { landmarkName: plandmarkName, pickTime, time: ptime },
        } = defaultNavBarProps;
        document.title = title;

        const pickTimes = pickTime ? pickTime : ptime ? ptime : '';
        const dropTimes = dropTime ? dropTime : dtime ? dtime : '';
        const formatPickTime = DateUitl.format(pickTimes, 'MM-DD HH:mm');
        const formatDropTime = DateUitl.format(dropTimes, 'MM-DD HH:mm');
        const times = DateUitl.diff({ start: pickTimes, end: dropTimes }).times || '';

        setCarListPageTitle({
            pickUpLocation: plandmarkName,
            returnLocation: landmarkName,
            pickUpDate: formatPickTime,
            returnDate: formatDropTime || '',
            titleTips: times || '1天',
            onTouchBar: 'window["onTouch"]()',
            success: function() {},
        });
    }

    render() {
        const {
            children,
            className,
            style,
            navBar,
            defaultNavBar,
            defaultNavBarProps,
            ...rest
        } = this.props;

        let targetNavBar = navBar;
        if (!targetNavBar && defaultNavBar) {
            targetNavBar = <NavBar {...defaultNavBarProps} />;
        }

        const cls = classnames(
            'component-page component-page-full-screen car-list-page',
            className
        );

        return (
            <main
                {...rest}
                className={cls}
                style={{
                    height: HEIGHT,
                    ...style,
                }}
            >
                {targetNavBar && !isCarRentalApp() && (
                    <div className="component-page-full-screen-header">
                        {targetNavBar}
                    </div>
                )}
                <div className="component-page-full-screen-body" ref={this.bodyRef}>
                    {children}
                </div>
            </main>
        );
    }
}
