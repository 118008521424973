// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

const ID = 'component-button';

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'warning']),
  size: PropTypes.oneOf(['large', 'small']),
  disable: PropTypes.bool
};

Button.defaultProps = {
  size: 'large',
  disable: false
};

export default function Button(props) {
  const {
    type, size, disable, children, onClick, className, ...rest
  } = props;
  const cls = classnames(className, ID, `${ID}-${type}`, `${ID}-${size}`, {
    [`${ID}-disable`]: disable
  });

  return (
    <div
      {...rest}
      role="button"
      className={cls}
      onClick={(event) => {
        disable || (onClick && onClick(event));
      }}
    >
      <div>{children}</div>
    </div>
  );
}
