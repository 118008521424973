/**
 * ErrorBoundary 页面级
 */
// @ts-nocheck
import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import { PageNormal as Page } from '../Page';
import Button from '../Button';


export default function ErrorBoundaryPage(props) {
  return (
    <ErrorBoundary
      {...props}
      placeholder={<ErrorBoundaryPagePlaceholder />}
    />
  );
}

function ErrorBoundaryPagePlaceholder() {
  const title = '对不起，程序出错了！';
  return (
    <Page
      defaultNavBarProps={{
        title
      }}
    >
      <div className="component-error-boundary-page-placeholder">
        <h1>{title}</h1>
        <div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            重新加载
          </Button>
        </div>
      </div>
    </Page>
  );
}
